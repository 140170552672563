<template>
  <div class="app-container">
    <div class="full">
      <el-tabs v-model="activeName" @tab-click="handleClick">
         <el-tab-pane v-for="item in auditData" :key="item.name" :label="item.label" :name="item.name">
           <el-form :inline="true">
             <el-form-item label="区域">
               <el-select clearable style="width:100%" v-model="params.city">
                 <el-option
                     v-for="(item, index) in cityList"
                     :key="'item_' + index"
                     :label="item.name"
                     :value="item.name"
                 ></el-option>
               </el-select>
             </el-form-item>
             <el-form-item label="用户身份">
               <el-select clearable style="width:100%" v-model="params.roleCode">
                 <el-option
                     v-for="(item, index) in roleList"
                     :key="'item_' + index"
                     :label="item"
                     :value="item"
                 ></el-option>
               </el-select>
             </el-form-item>
             <el-form-item label="手机号">
               <el-input v-model="params.username"> </el-input>
             </el-form-item>
             <el-form-item label="用户名">
               <el-input v-model="params.nickname"></el-input>
             </el-form-item>
             <el-form-item label="身份证号">
               <el-input v-model="params.idCardCode"></el-input>
             </el-form-item>
             <el-form-item>
               <el-button type="primary" @click="getList">查询</el-button>
             </el-form-item>
             <el-form-item>
               <el-button type="primary" @click="exportExcel">导出</el-button>
               <!-- <a
                 href="http://signin.sccqhome.cn/api/sys/user/export"
                 download="用户注册表.xls"
                 type="download"
               >
                 导出</a
               > -->
             </el-form-item>
           </el-form>
           <el-table
               :data="tableData"
               style="width: 100%;margin-bottom: 20px;"
               border
               stripe
               default-expand-all
               align="center"
           >
             <el-table-column prop="city" label="参会地"> </el-table-column>
             <el-table-column prop="roleCode" label="角色"> </el-table-column>
             <el-table-column prop="nickname" label="姓名"> </el-table-column>
             <!-- <el-table-column prop="companyName" label="公司名称"> </el-table-column> -->
             <!-- <el-table-column prop="position" label="职务"> </el-table-column> -->
             <el-table-column prop="username" label="电话"> </el-table-column>
             <el-table-column prop="idCardCode" label="身份证"> </el-table-column>
             <el-table-column prop="orderNum" label="状态">
               <template slot-scope="scope">
                 {{
                   scope.row.status == 1
                       ? "未审核"
                       : "审核通过"
                 }}
               </template>
             </el-table-column>
             <el-table-column prop="icon" label="操作">
               <template slot-scope="scope">
                 <el-button
                     type="text"
                     v-if="scope.row.status == 1"
                     @click="exportRow(scope.row)"
                 >
                   审核</el-button
                 >

                 <el-button type="text" @click="updateUser(scope.row, 'edit')"
                 >修改</el-button
                 >
                 <el-button type="text" @click="updateUser(scope.row, 'info')">查看</el-button>
                 <el-button v-if="scope.row.diploma!=null" type="text" @click="certificate(scope.row)">删除证书</el-button>
                 <el-dropdown>
                <span class="el-dropdown-link">
                  更多<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                   <el-dropdown-menu slot="dropdown">
                     <el-dropdown-item>
                       <el-button type="text" v-if="scope.row.signNum == 1" @click="showSignStatus(scope.row)">补卡</el-button>
                     </el-dropdown-item>
                     <el-dropdown-item>
                       <el-button type="text" @click="showSignStatus(scope.row, 'info')">打卡记录</el-button>
                     </el-dropdown-item>
                     <el-dropdown-item>
                       <el-button type="text" @click="delUser(scope.row)">删除</el-button>
                     </el-dropdown-item>
                   </el-dropdown-menu>
                 </el-dropdown>
               </template>
             </el-table-column>
           </el-table>
           <el-pagination
               @size-change="sizechange"
               @current-change="pagechange"
               layout="total, sizes, prev, pager, next, jumper"
               :page-sizes="[10, 20, 50, 100]"
               :current-page="page.index"
               :page-size="page.size"
               :total="page.total"
           >
           </el-pagination>
         </el-tab-pane>
      </el-tabs>
      <el-dialog
          title="是否确定审核通过？"
          :visible.sync="dialogVisible"
          width="300px"
          :before-close="handleClose1"
      >
        <!-- <el-input type="text" v-model="msg"></el-input> -->
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="exportsubmit('resolve')"
          >确定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        title="签到记录"
        :visible.sync="signVisible"
        v-if="signVisible"
        width="600px"
        :before-close="handleClose"
      >
        <!-- <el-table :data="signList" v-if="signVisible">
          <el-table-column prop="signTime" label="签到时间"> </el-table-column>
        </el-table> -->
        <table class="tableClass">
          <tr>
            <th>应签到时间</th>
            <th>打卡时间</th>
            <th v-if="!isInfo">操作</th>
          </tr>
          <tr>
            <td>{{ qrcodemsg.trainStartTime }}</td>
            <td>{{ fisrtTime }}</td>
            <td v-if="!isInfo">
              <el-button
                type="text"
                v-if="fisrtTime == ''"
                @click="clocklate(1)"
                >补卡</el-button
              >
            </td>
          </tr>
          <tr>
            <td>{{ qrcodemsg.noonTime }}</td>
            <td>{{ secondTime }}</td>
            <td v-if="!isInfo">
              <el-button
                type="text"
                v-if="secondTime == ''"
                @click="clocklate(2)"
                >补卡</el-button
              >
            </td>
          </tr>
          <tr>
            <td>{{ qrcodemsg.afterTime }}</td>
            <td>{{ threeTime }}</td>
            <td v-if="!isInfo">
              <el-button
                type="text"
                v-if="threeTime == ''"
                @click="clocklate(3)"
                >补卡</el-button
              >
            </td>
          </tr>
          <tr>
            <td>{{ qrcodemsg.trainEndTime }}</td>
            <td>{{ lastTime }}</td>
            <td v-if="!isInfo">
              <el-button type="text" v-if="lastTime == ''" @click="clocklate(4)"
                >补卡</el-button
              >
            </td>
          </tr>
        </table>

        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="signVisible = false"
            >确定</el-button
          >
        </div>
      </el-dialog>
      <el-dialog
        title="用户信息"
        :visible.sync="userVisible"
        v-if="userVisible"
        width="600px"
        :before-close="handleCloseUser"
      >
        <el-form
          style="padding-right:40px"
          :model="userForm"
          ref="userForm"
          label-width="140px"
          :rules="rules"
        >
          <el-form-item label="参会地" prop="city">
            <el-select
              style="width:100%"
              v-model="userForm.city"
              :disabled="isUserInfo"
            >
              <el-option
                v-for="(item, index) in cityList"
                :key="'item_' + index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="用户名" prop="nickname">
            <el-input
              v-model="userForm.nickname"
              :disabled="isUserInfo"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="小区名称"
            v-if="userForm.roleCode == '业主代表'"
            prop="houseName"
          >
            <el-input
              v-model="userForm.houseName"
              :disabled="isUserInfo"
            ></el-input>
          </el-form-item>
          <el-form-item label="从业单位" prop="companyName">
            <el-input
              v-model="userForm.companyName"
              :disabled="isUserInfo"
            ></el-input>
          </el-form-item>
          <el-form-item label="用户角色" prop="roleCode">
            <el-select
              style="width:100%"
              :disabled="isUserInfo"
              v-model="userForm.roleCode"
            >
              <el-option
                v-for="(item, index) in roleList"
                :key="'item_role' + index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="职务"
            v-if="userForm.roleCode != '业主代表'"
            prop="position"
          >
            <el-input
              v-model="userForm.position"
              :disabled="isUserInfo"
            ></el-input>
          </el-form-item>
          <el-form-item label="电话" prop="username">
            <el-input
              v-model="userForm.username"
              :disabled="isUserInfo"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证" prop="idCardCode">
            <el-input
              v-model="userForm.idCardCode"
              :disabled="isUserInfo"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="统一社会信用代码"
            prop="companyCode"
            v-if="
              userForm.roleCode == '物业企业' || userForm.roleCode == '协会'
            "
          >
            <el-input
              v-model="userForm.companyCode"
              :disabled="isUserInfo"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="userVisible = false">取消</el-button>
          <el-button type="primary" @click="submitUser('userForm')">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      page: {
        index: 1,
        size: 10,
        total: 0,
      },
      dialogVisible: false,
      signVisible: false,
      userVisible: false,
      msg: "", // 审核意见
      formData: {
        pid: "",
        openId: "",
      },
      signList: [],
      qrcodemsg: {},
      params: {
        city: "",
        roleCode: "",
        username: "",
        nickname: "",
        idCardCode: "",
      },
      cityList: [
        {
          name: "成都市",
        },
        {
          name: "南充市",
        },
        {
          name: "广元市",
        },
        {
          name: "巴中市",
        },
        {
          name: "广安市",
        },
        {
          name: "眉山市",
        },
        {
          name: "遂宁市",
        },
        {
          name: "达州市",
        },

        {
          name: "绵阳市",
        },
        {
          name: "资阳市",
        },

        {
          name: "德阳市",
        },
        {
          name: "内江市",
        },
        {
          name: "乐山市",
        },
        {
          name: "宜宾市",
        },
        {
          name: "泸州市",
        },
        {
          name: "凉山彝族自治州",
        },

        {
          name: "自贡市",
        },
        {
          name: "攀枝花市",
        },
        {
          name: "甘孜藏族自治州",
        },
        {
          name: "阿坝藏族羌族自治州",
        },
        {
          name: "雅安市",
        },
      ],
      roleList: ["物业企业", "主管部门", "协会", "业主代表"],
      isInfo: true, // 是否查看打卡记录详情
      isUserInfo: true, // 是否查看用户信息
      openId: "", // 用户ID
      dictionariesId: "", // 会议id
      fisrtTime: "", // 第一次打卡时间
      secondTime: "", // 第二次打卡时间
      threeTime: "", // 第三次打卡时间
      lastTime: "", // 第四次打卡时间

      userForm: {}, //用户信息表单
      rules:{
        username: [
          { pattern:/^1[3-9]\d{9}$/, message: "请输入正确的手机号", trigger: "blur" },
        ],
        idCardCode: [
          { pattern:/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: "请输入正确的身份证号", trigger: "blur"}
        ],
        companyCode: [
          { pattern:/[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/, message: "请输入正确的统一社会信用代码", trigger: "blur"}
        ],
      },
      activeName: '1',
      auditData:[
        {
          name:'1',
          label:'未审核'
        },
        {
          name:'2',
          label:'审核通过'
        },
      ],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    pagechange(val) {
      this.page.index = val;
      this.getList();
    },
    sizechange(val) {
      this.page.size = val;
      this.getList();
    },
    delUser(row) {
      this.$confirm("是否确定删除当前用户?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.get(`/sys/user/delete/${row.id}`).then((res) => {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    certificate(row){
      this.$confirm("是否确定删除当前用户的证书?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$axios.get(`sys/user/removeDiploma/${row.openId}`).then((res) => {
              if (res.data.code == 200) {
                this.$message.success("删除成功!");
                this.getList();
              }
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });

    },
    updateUser(row, type) {
      this.isUserInfo = type == "info" ? true : false;
      this.userForm = JSON.parse(JSON.stringify(row));
      this.userVisible = true;
    },
    handleCloseUser() {
      this.userVisible = false;
    },
    submitUser(userForm) {
      this.$refs[userForm].validate((valid) => {
        if (valid) {
          if (this.isUserInfo) {
            this.userVisible = false;
            return false;
          }
          this.$axios.post("/sys/user/update", this.userForm).then((res) => {
            // this.tableData = res.data.data.records;
            // this.page.total = res.data.data.total;
            console.log(res);
            if (res.data.code == 200) {
              this.$message.success("修改成功!");
              this.getList();
              this.userVisible = false;
            } else {
              this.userVisible = false;
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      })

    },
    clocklate(type) {
      let obj = {
        types: type,
        openId: this.openId,
        dictionariesId: this.dictionariesId,
      };
      this.$axios.post("/sys/sign/save", obj).then((res) => {
        if (res.data.code == 200) {
          this.signVisible = false;
          this.$message.success("补卡成功");
        }
        // this.tableData = res.data.data.records;
        // this.page.total = res.data.data.total;
      });
      console.log(type, 11111);
    },
    handleClose1() {
      this.dialogVisible = false;
    },
    handleClose() {
      this.signVisible = false;
    },
    getList(type) {
      this.params.current = type?1:this.page.index;
      this.params.size = type?10:this.page.size;
      this.params.address = this.serachaddress;
      this.params.status = Number(this.activeName);
      this.params.types = 1;
      this.$axios.post("/sys/user/findList", this.params).then((res) => {
        this.tableData = res.data.data.records;
        this.page.total = res.data.data.total;
      });
    },
    exportRow(row) {
      this.formData.pid = row.pid;
      this.formData.openId = row.openId;
      this.dialogVisible = true;
    },
    /**
     * 签到记录
     */
    showSignStatus(row, type) {
      this.openId = row.openId;
      if (type == "info") {
        this.isInfo = true;
      } else {
        this.isInfo = false;
      }

      this.getCodeList(row.openId);
    },
    getCodeList(oppenid) {
      this.$axios.get(`sys/sign/repairCard/${oppenid}`).then((res) => {
        if (res.data.code == 200) {
          this.signVisible = true;
          this.signList = res.data.data.lis;
          if (res.data.data.lis.length > 0) {
            res.data.data.lis.forEach((item) => {
              if (item.types == 1) {
                this.fisrtTime = item.signTime;
              }
              if (item.types == 2) {
                this.secondTime = item.signTime;
              }
              if (item.types == 3) {
                this.threeTime = item.signTime;
              }
              if (item.types == 4) {
                this.lastTime = item.signTime;
              }
            });
          } else {
            this.fisrtTime = "";
            this.secondTime = "";
            this.threeTime = "";
            this.lastTime = "";
          }
          this.qrcodemsg = res.data.data.dtn;
          this.dictionariesId = res.data.data.dtn.id;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    /**
     * 审核提交
     */
    exportsubmit(agree) {
      let status = agree == "resolve" ? 2 : agree == "reject" ? -1 : -1;
      this.formData.status = status;
      this.formData.msg = this.msg;

      this.$axios.post("sys/user/examine", this.formData).then((res) => {
        this.$message({
          message: "恭喜你，操作成功",
          type: "success",
        });
        this.getList();
        this.dialogVisible = false;
      });
    },
    /**
     * 导出列表
     */
    exportExcel() {
      // var xhr = new XMLHttpRequest();
      // xhr.open("GET", '/sys/user/export', true);
      // xhr.responseType = "blob";
      // xhr.onload = function() {
      //   if (xhr.status === 200) {
      //     cb(xhr.response);
      //   }
      // };
      // xhr.send();
      // this.$axios.get("/sys/user/export").then((res) => {
      //   console.log(res, 123132132);
      //   if (window.navigator.msSaveOrOpenBlob) {
      //     navigator.msSaveBlob(res.data, "注册用户.xls");
      //   } else {
      //     let blob = new Blob([res.data], { type: "application/octet-stream" });
      var link = document.createElement("a");
      var body = document.querySelector("body");
      //     // let urlAll =     window.URL.createObjectURL(blob),
      //     //     tempUrl = urlAll.split("/");
      //     // console.log(tempUrl)
      this.params;
      link.href =
        "http://sign.sccqhome.cn/api/sys/user/export?city=" +
        // "http://192.168.2.36:8189/sys/user/export?city=" +
        this.params.city +
        "&username=" +
        this.params.username +
        "&roleCode=" +
        this.params.roleCode +
        "&nickname=" +
        this.params.nickname +
        "&idCardCode=" +
        this.params.idCardCode +
        "&status=" +
        this.activeName;

      link.download = "注册用户.xls"; // fix Firefox
      link.style.display = "none";
      body.appendChild(link);
      link.click();
      body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
      //   }
      //   // this.$message({
      //   //   message: "恭喜你，操作成功",
      //   //   type: "success",
      //   // });
      //   // this.getList();
      //   // this.dialogVisible = false;
      // });
    },
    /**切换tabs**/
    handleClick(tab, event){
      this.page.index = 1
      this.page.size = 10
      this.getList(true)
    },
  },

  created() {},
  watch: {},
};
</script>

<style scoped>
.tableClass {
  width: 100%;
  text-align: center;
  border: 1px solid #eee;
  border-bottom: 0;
  border-collapse: collapse;
}

.tableClass tr td,
.tableClass tr th {
  line-height: 48px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409EFF;
  margin-left: 10px;;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>
